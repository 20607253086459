.darker {
  position: relative;
  width: 100%;
  height: 100%;
  background-image: url(https://fauux.neocities.org/bg_darker.gif);
  animation: darkestDown 80s linear infinite;
  filter: hue-rotate(90deg); /* Rotate the hue by 90 degrees */
  background-color: rgb(213, 140, 202);
}

.dark {
  position: relative;
  width: 100%;
  height: 100%;
  background-image: url(https://fauux.neocities.org/bg_dark_anim_0_08.gif);
  animation: darkest 60s linear infinite;
  opacity: 65%;
  filter: hue-rotate(90deg);
  /* background-color: darkgreen; */
}

@keyframes darkest {
  from {
    background-position-y: 1024px;
  }
  to {
    background-position-x: -1024px;
  }
}

@keyframes darkestDown {
  from {
    background-position-y: -1024px;
  }
  to {
    background-position-x: 1024px;
  }
}

.opacity {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  animation: opacityBg 0.3s linear forwards;
  animation-delay: 0.6s;
}

@keyframes opacity {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.outer-bg {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 42, 255, 0.5); /* Semi-transparent red background */
  mix-blend-mode: multiply; /* Use multiply blend mode to blend with elements underneath */
  background-blend-mode: color; /* Apply blending only to the color of the background */
}
