.App {
  position: relative;
  z-index: 1;
  text-align: center;
  min-height: 100vh;
  overflow: hidden;
  width: 100%;
  margin: 0 auto;
}

.Background {
  position: fixed;
  top: -100px;
  left: -100px;
  width: 120%;
  height: 150%;
  z-index: 0;
  background-image: url("https://fauux.neocities.org/bg_9992.gif");
  background-repeat: repeat;
  background-size: 2.5%;
  transform: rotate(5deg);
  animation: moveBackground 100s linear infinite;
}

.app-body {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

@font-face {
  font-family: "Quicksand";
  src: url("./assets/fonts/Quicksand.ttf") format("truetype");
  /* You can specify other font properties like font-weight and font-style if needed */
}

@keyframes moveBackground {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 100% 100%;
  }
}

.logo-container {
  position: relative;
  margin: 30px auto;
  width: 300px;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* background-color: #ff7300; */
}


/* .rainbow-layer {
  transform: rotate(45deg);
  position: absolute;
  width: 100%;
  height: 100%;
  border: 4px solid black;
  border-radius: 50%;
  overflow: hidden;
  background: linear-gradient(
    90deg,
    #ff0000 0%,
    #ff7300,
    #fffb00,
    #48ff00,
    #00ffd5,
    #002bff,
    #7a00ff,
    #ff00c8,
    #ff0000 100%
  );
  background-size: 400%;
  animation: glowing 20s linear infinite;
  z-index: -1;
} */


.landing-logo {
  position: absolute;
  width: calc(100% - 32px);
  height: calc(100% - 32px);
  color: #ffffff;
  font-family: Quicksand, sans-serif;
  font-size: large;
  /* border-radius: 50%; */
  padding: 16px;
  background-color: rgba(255, 255, 255, 1);
}

@keyframes glowing {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 400% 0;
  }
}

@media (max-width: 600px) {
  .logo-container {
    position: relative;
    margin: 30px auto;
    width: 200px;
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    /* background-color: #ff7300; */
  }

  .Background {
    position: fixed;
    top: -50px;
    width: 250%;
    height: 150%;
  }
}

@media (max-width: 800px ) {
  .Background {
    position: fixed;
    top: -50px;
    left: -50px;
    width: 250%;
    height: 150%;
  }
}
