.signup {
  position: relative;
  width: 100%;
  color: #ffffff;
  height: 100%;
  text-align: center;
}

.signup-inner {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 120px;
  width: calc(100% - 40px);
  background-color: #000000bf;
  margin-top: 40px;
  margin-left: 20px;
  margin-right: 20px;
}

.signup-inner-title {
  color: #ffffff;
  padding: 5px;
  font-family: Quicksand;
  font-size: 16px;
  font-weight: bolder;
}

.signup-inner-input {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.signup-inner-input input::placeholder {
  text-align: center;
}

.signup-inner-field {
  display: flex;
  flex-direction: row;
}

.signup-inner-field input {
  width: max-content;
  background-color: whitesmoke;
  height: 25px;
  font-size: 16px;
  font-family: 'Quicksand';
  font-weight: bold;
  display: flex;
  flex-direction: column;
}

.signup-inner-button button {
  margin-left: 14px;
  height: 32px;
  background-color: goldenrod;
  font-size: 16px;
}

@media (max-width: 600px) {
  .signup-inner {
    height: 180px;
  }

  .signup-inner-field {
    flex-direction: column;
  }

  .signup-inner-button {
    margin-top: 10px;
  }

  .signup-inner-button button {
    margin-left: 0px;
  }
}
