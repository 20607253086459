.header {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: #000000bf;
  margin-left: 20px;
  margin-right: 20px;
  color: #ffffff;
  width: calc(100% - 40px);
  height: 40px;
  text-align: center;
  font-family: Quicksand;
  font-weight: bolder;
}

.header-inner {
  position: relative;
  display: flex;
  justify-content: space-around;
  height: 100%;
  width: 100%;
}

.header-inner a {
  position: relative;
  display: inline-block;
  line-height: 40px;
  color: #afb4c0;
  font-size: 16px;
  width: 120px;
  transition: text-shadow 0.3s ease-in-out, color 0.3s ease-in-out;
}

.header-inner a:hover {
  text-shadow: 0 0 18px rgba(255, 215, 0, 0.5);
  color: #ffd700;
}

.header-inner a:active {
  color: #b8860b;
}

@media (max-width: 600px) {
  .header {
    height: 100%;
    position: relative;
    background-color: #00000000;
    flex-direction: column;
    left: 0;
    transform: translate(0);
  }
  .header-inner {
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
  }

  .header-inner a {
    background-color: #000000bf;
    color: #ffffff;
    height: 40px;
    text-align: center;
    width: 100%;
    margin-top: 10px;
  }
}
