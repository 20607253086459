.footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  background-color: #000000BF;
  color: #ffffff;
  padding: 10px;
  text-align: center;
  height: 20px;
  text-align: center;
}

.footer-copyright {
  position: absolute;
  left: 50%;
  bottom: 25%;
  transform: translate(-50%, -50%);
  text-align: center; 
  font-family: quicksand;
}

.footer-socials {
  position: absolute;
  right: 60px;
}

.footer-socials-inner {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin-right: 10px;
}

.footer-socials-inner img {
  fill: #ffffff;
  width: 20px;
  margin: 0 10px;
  filter: grayscale(100%);
  transition: filter 0.3s ease-in, transform 0.3s ease-in;
}

.footer-socials-inner img:hover {
  filter: brightness(0) invert(1);
  transform: scale(1.1);
}

@media (max-width: 600px) {
  .footer-socials {
    position: relative;
    right: 0;
  }

  .footer-copyright {
    position: relative;
  }

  .footer-socials-inner {
    justify-content: center;
  }

  .footer-socials-inner img {
    width: 3cap;
    padding-left: 10px;
    padding-right: 10px;
  }

  .footer-copyright {
    position: relative;
    font-size: 16px;
    bottom: 0;
    margin-top: 20px;
  }

  .footer {
    height: 60px;
    display: flex;
    flex-direction: column;
  }
  
  
}